<template>
  <b-container class="mt-4">
    <b-spinner v-if="loading"></b-spinner>
    <b-row v-if="!loading" class="mb-2">
      <b-col>
        <h4>List of configured client ecosystem integrations</h4>
      </b-col>
    </b-row>
    <b-row v-if="!loading">
      <b-col>
        <table class="table table-bordered table-striped">
          <thead class="thead-dark">
          <tr>
            <th>Client ID</th>
            <th>Name</th>
            <th v-for="(integration, idx) in integrationHeaders" :key="idx">{{ integration }}</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="client in clients" :key="client.id">
            <td>{{ client.id }}</td>
            <td>{{ client.display_name }}</td>
            <td v-for="(active, integrationKey) in client.integrations" :key="integrationKey">
              <b-spinner v-if="active === null" type="grow" small></b-spinner>
              <span v-else-if="active" class="text-success">
              <feather type="check"></feather>
            </span>
              <span v-else class="text-danger">
            <feather type="x"></feather>
            </span>
            </td>
            <td>
              <b-button variant="light"
                        v-if="$acl.check('onboarding.clients.integrations.edit')"
                        :to="`/onboarding/integrations/${client.id}`">
                <!-- TODO Check why named route is not working -->
                <feather type="edit-2"></feather>
              </b-button>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';

export default {
  name: 'ClientIntegrations',
  data() {
    return {
      clients: [],
      loading: true,
      availableIntegrations: {
        castlight: 'Castlight',
        bridge_health: 'Bridge Health',
        highmark: 'HighMark',
        benefitsolver: 'Benefitsolver',
        solera: 'Solera',
        amwell: 'Amwell',
      },
      integrations: [],
    };
  },
  computed: {
    integrationHeaders() {
      return Object.values(this.availableIntegrations);
    },
  },
  async mounted() {
    this.$store.dispatch('Core/fetchClientsWithIntegration').then(clientItems => {
      clientItems.forEach(item => {
        item.integrations = this.defaultClientIntegrations();
      });
      this.clients = clientItems;

      this.clients.forEach(client => {
        this.loadClientIntegrations(client).catch(err => {
          console.error(err);
        });
      });
    }).finally(() => {
      this.loading = false;
    });
  },
  methods: {
    async loadClientIntegrations(client) {
      client.configuration = client.configuration || {};
      Object.entries(client.configuration).forEach(([ configKey, configValue ]) => {
        if (configKey in client.integrations) {
          client.integrations[configKey] = configValue;
        }
      });

      const { ecosystems } = client;
      ecosystems.forEach(ecosystem => {
        if (ecosystem.ecosystem_id in client.integrations) {
          client.integrations[ecosystem.ecosystem_id] = Boolean(ecosystem.enabled);
        }
      });

      Object.keys(client.integrations).forEach(key => {
        if (client.integrations[key] === null) {
          client.integrations[key] = false;
        }
      });
      const clientIndex = this.clients.findIndex(c => c.id === client.id);
      Vue.set(this.clients, clientIndex, client);
    },
    async getEcosystemIntegrations(clientId) {
      try {
        const { data } = await axios.get(`v1/clients/${clientId}/integrations`);
        data.ecosystems = data.ecosystems || [];
        return data;
      } catch (e) {
        console.error(e);
      }

      return { ecosystems: [] };
    },
    defaultClientIntegrations() {
      const availableIntegrations = JSON.parse(JSON.stringify(this.availableIntegrations));
      return Object.keys(availableIntegrations).reduce((carry, item) => {
        carry[item] = null;
        return carry;
      }, {});
    },
  },
};
</script>

<style scoped>

</style>
